import 'video-react/dist/video-react.css';
import './App.scss';
import { lazy, Suspense } from 'react';
import { Router } from '@reach/router';
import { initializeEntry } from '../pages/route';
import pages from '../pages/route.config.json';

const Loading = () => null;

const StemuliLayout = lazy(() => import('../app/layout'));
const StemuliPreload = lazy(() => import('./layout/preload'));
const ClassProvider = lazy(() => import('../contexts/ClassProvider'));

const ApplicationRoutes = () => {
  const routes = initializeEntry(pages);

  return (
    <main data-id={'main-entry-point'}>
      <Suspense fallback={<Loading />}>
        <Router primary>
          <ClassProvider path={'/'}>
            <StemuliPreload path={'/'}>
              <StemuliLayout path={'/'}>{routes.private}</StemuliLayout>
            </StemuliPreload>
          </ClassProvider>
          {routes.public}
        </Router>
      </Suspense>
    </main>
  );
};

export default ApplicationRoutes;
