import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_COMMENTS = createActionType('FIND_COMMENTS', 'COMMENTS');

export const findComments = (
  referenceId: string,
  commentType?: string,
  option?: ActionOption
) => ({
  type: FIND_COMMENTS.START,
  meta: {
    referenceId,
    commentType,
    ...option,
  },
});
