import { findNotifications } from './findNotification';
import { readNotification } from './readNotification';
import { createNotificationChannel } from './registerDevice';
import { removeDeviceFromChannel } from './removeDevice';

export default [
  findNotifications,
  readNotification,
  createNotificationChannel,
  removeDeviceFromChannel,
];
