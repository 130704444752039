import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';

export const FIND_ACTIVITY_SUBMISSIONS = createActionType(
  'FIND_ACTIVITY_SUBMISSIONS',
  'ACTIVITY'
);

export const findActivitySubmissions = (option?: ActionOption) => ({
  type: FIND_ACTIVITY_SUBMISSIONS.START,
  meta: {
    ...option,
  },
});
