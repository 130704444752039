import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const FIND_SYLLABUS = createActionType('FIND_SYLLABUS', 'SYLLABUS');

export const findSyllabus = (option?: ActionOption) => ({
  type: FIND_SYLLABUS.START,
  meta: {
    ...option,
  }
});
