import { createSelector } from 'reselect';
import { ClassMemberState } from '../../redux/reducers/classMember';

const classMembers = (classMember: ClassMemberState, key: string) =>
  classMember?.classMembers[key] || [];

export const selectUserClasses = createSelector(
  classMembers,
  (classMembers: LessonNameSpace.ClassMember[]) => {
    return classMembers.map((c) => c.class);
  }
);

export const selectClassList = createSelector(
  selectUserClasses,
  (userClasses: LessonNameSpace.Class[]) => {
    return userClasses.map(({ _id, title }) => ({ _id, title })
    );
  }
);
