import {
  completeActivitySubmission,
  findActivity,
  findActivityByClass,
  findActivityByPractice,
  findActivitySubmissions,
  getActivity,
  getQuestionSubmissions,
  submitActivityQuestion,
  studentActivitySubmissions,
} from 'src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from 'src/redux/types';
import { useEffect } from 'react';

export type ActivityProps = {
  key: string;
  userId?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
  doFindPractice?: boolean;
  classId?: string;
};

export type ActivityType = {
  handleFetchByClass: (classId: string, options?: ActionOption) => void;
  handleFetchByPractice: (classId: string, options?: ActionOption) => void;
  handleFetchOne: (id: string, options?: ActionOption) => void;
  activities: LessonNameSpace.Activity[];
  currentActivity: LessonNameSpace.Activity;
  questionSubmission: LessonNameSpace.QuestionSubmission[];
  activitySubmission: LessonNameSpace.ActivitySubmission[];
  studentSubmissions: LessonNameSpace.ActivitySubmission[];
  submitQuestion: (
    activityId: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => void;
  getQuestionSubmissions: (options?: ActionOption) => void;
  findActivitySubmissions: (options?: ActionOption) => void;
  studentActivitySubmissions: (options?: ActionOption) => void;
  completeActivitySubmission: (
    activityId: string,
    options?: ActionOption
  ) => void;
};

export const useActivity = ({
  params,
  key,
  doFind,
  doFindPractice,
  classId,
}: ActivityProps): ActivityType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params,
  };

  const {
    activities,
    currentActivity,
    questionSubmission,
    activitySubmission,
    studentSubmissions,
  } = useSelector(({ activity }: RootState) => ({
    activities: activity?.activities[key] || [],
    currentActivity: activity?.currentActivity[key] ?? {},
    questionSubmission: activity?.questionSubmission[key] ?? [],
    activitySubmission: activity?.activitySubmission[key] ?? [],
    studentSubmissions: activity?.studentActivitySubmissions[key] ?? [],
  }));

  useEffect(() => {
    if (doFind) {
      dispatch(
        findActivity({
          params,
          key,
        })
      );
    }
  }, [doFind]);

  useEffect(() => {
    if (doFindPractice && classId) {
      dispatch(
        findActivityByPractice(classId, {
          params,
          key,
        })
      );
    }
  }, [doFindPractice, classId]);

  const handleFetchByClass = (classId: string, options?: ActionOption) => {
    dispatch(findActivityByClass(classId, { ...defaultOptions, ...options }));
  };
  const handleFetchByPractice = (classId: string, options?: ActionOption) => {
    dispatch(
      findActivityByPractice(classId, { ...defaultOptions, ...options })
    );
  };

  const handleFetchOne = (id: string, options?: ActionOption) => {
    dispatch(getActivity(id, { ...defaultOptions, ...options }));
  };

  const submitQuestion = (
    activityId: string,
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(submitActivityQuestion(activityId, payload, options));
  };
  const _completeActivitySubmission = (
    activityId: string,
    options?: ActionOption
  ) => {
    dispatch(completeActivitySubmission(activityId, options));
  };

  const _getQuestionSubmissions = (options?: ActionOption) => {
    dispatch(getQuestionSubmissions(options));
  };

  const _findActivitySubmissions = (options?: ActionOption) => {
    dispatch(findActivitySubmissions(options));
  };

  const _studentActivitySubmissions = (options?: ActionOption) => {
    dispatch(studentActivitySubmissions({ ...defaultOptions, ...options }));
  };

  return {
    activities,
    currentActivity,
    handleFetchOne,
    handleFetchByClass,
    handleFetchByPractice,
    submitQuestion,
    questionSubmission,
    activitySubmission,
    studentSubmissions,
    getQuestionSubmissions: _getQuestionSubmissions,
    completeActivitySubmission: _completeActivitySubmission,
    findActivitySubmissions: _findActivitySubmissions,
    studentActivitySubmissions: _studentActivitySubmissions,
  };
};
