import { findActivityByClass } from './findActivityByClass';
import { findActivity } from './findActivity';
import { getActivity } from './getActivity';
import { submitActivityQuestion } from './submitActivityQuestion';
import { getQuestionSubmissions } from './getQuestionSubmissions';
import { completeActivitySubmission } from './completeActivitySubmission';
import { findActivitySubmissions } from './findActivitySubmissions';
import { findActivityByPractice } from './findActivityByPractice';
import { getActivityByPractice } from './getActivityByPractice';
import { studentActivitySubmissions } from './studentActivitySubmissions';

export default [
  findActivityByClass,
  findActivity,
  getActivity,
  submitActivityQuestion,
  getQuestionSubmissions,
  completeActivitySubmission,
  findActivitySubmissions,
  findActivityByPractice,
  getActivityByPractice,
  studentActivitySubmissions,
];
