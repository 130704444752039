import { pick } from 'lodash';
import { createSelector } from 'reselect';
import { NotificationState } from '../../redux/reducers/notification';

const notifications = (notification: NotificationState, key: string) =>
  notification?.notifications[key] || [];

export const selectNotificationsData = createSelector(
  notifications,
  (notifications) => {
    return notifications.map(
      ({ _id, data, type, createdAt, viewed, notificationLastReadAt }) => {
        return {
          _id,
          notificationType: type,
          createdAt,
          viewed,
          notificationLastReadAt,
          data,
        };
      }
    );
  }
);
