import {
  createElement,
  FunctionComponentElement,
  lazy,
  ReactNode,
  Suspense,
} from "react";
import { isEmpty, isObject, isString } from "lodash";
import { Router } from "@reach/router";
import UUID from "uuidjs";

const generateId = (prefix = "") => {
  return prefix
    .replace(/\s/g, "-")
    .toLowerCase()
    .concat("/")
    .concat(UUID.genV4().toString());
};

export interface RouteInterface {
  name: string;
  path: string;
  private: boolean;
  isExternalPath?: boolean;
  entry: boolean;
  default?: boolean;
  asPath?: string;
  children?: Array<Omit<RouteInterface, "private"> | string>;
}

const constructPath = (...args: string[]) => {
  return args.join("").replace(/\/\//g, "/");
};

/**
 * Initialises a route entry if the entry property is provided
 * @param page
 * @param components
 * @param baseDir
 * @param basepath
 */
const createEntry = (
  page: RouteInterface | Omit<RouteInterface, "inLayout" | "private">,
  components: FunctionComponentElement<any>[],
  baseDir: string,
  basepath?: string
) => {
  if (page.entry) {
    // Global splits [value] paths and transforms them to :value
    const splitToParams = (page.asPath ?? page.path)
      .replace(/\[/g, ":")
      .replace(/\]/g, "");
    // Constructs a URL paths
    const routePath = constructPath(
      basepath ? `${basepath}/` : "",
      splitToParams
    );

    const importPath = page.isExternalPath
      ? page.path.replace("/*", "")
      : constructPath(baseDir, page.path.replace("/*", ""));

    const component = lazy(async () => import(`${importPath}`));

    if (page.default) {
      components.push(
        createElement(component, {
          default: true,
          key: generateId("@@stm/route/".concat(page.name)),
        })
      );
      return;
    }

    components.push(
      createElement(component, {
        path: routePath,
        key: generateId("@@stm/route/".concat(page.name)),
      })
    );
  }
};

/**
 * Import the children of a route and maps them to a path
 * @param page
 * @param components
 * @param baseDir
 * @param basepath
 */
const initializeRoutes = (
  page: RouteInterface | Omit<RouteInterface, "inLayout" | "private">,
  components: FunctionComponentElement<any>[],
  baseDir: string,
  basepath?: string
) => {
  createEntry(page, components, baseDir, basepath);

  const isWildCard = /(?:\/\*)/.test(page.path);

  const hasChildren = Array.isArray(page?.children) && !isEmpty(page?.children);

  if (!hasChildren || isWildCard || page.default) return;

  if (hasChildren && !isWildCard && !page.default) {
    for (const child of page?.children ?? []) {
      if (isString(child)) {
        // Global splits [value] paths and transforms them to :value

        // Constructs a URL paths
        const routePath = constructPath(
          basepath ? `${basepath}/` : "",
          page.asPath ?? page.path,
          "/",
          child
        )
          .replace(/\[/g, ":")
          .replace(/\]/g, "");

        const importPath = page.isExternalPath
          ? constructPath(page.path, "/", child).replace("/*", "")
          : constructPath(baseDir, page.path, "/", child).replace("/*", "");

        // Lazily loads the file from the folder.
        const childComponent = lazy(() => import(`${importPath}`));

        // Appends the file to the components list
        components.push(
          createElement(childComponent, {
            path: routePath,
            key: generateId("@@stm/route/".concat(routePath)),
          })
        );
      }

      if (isObject(child)) {
        // Recursively loads file
        initializeRoutes(
          Object.assign(
            {},
            child,
            {
              path: constructPath(page.path, "/", child?.path),
            },
            child?.asPath && {
              asPath: constructPath(page.path, "/", child?.asPath),
            }
          ),
          components,
          baseDir,
          basepath
        );
      }
    }
  }
};

/**
 * Creates the import files and maps them to a route
 * @param page
 * @param baseDir
 * @param basepath
 */
const initializeImports = (
  page: RouteInterface,
  baseDir: string,
  basepath?: string
) => {
  const components: FunctionComponentElement<any>[] = [];

  initializeRoutes(page, components, baseDir, basepath);

  return components.flat(1000);
};

interface InitializeEntry {
  public: FlatArray<FunctionComponentElement<any>[], 1000>[];
  private: FlatArray<FunctionComponentElement<any>[], 1000>[];
}

export const initializeEntryWithRouter = (
  pages: RouteInterface[],
  baseDir = "./",
  basepath?: string
): ReactNode => {
  const Loading = () => null;
  const routes = pages
    .map((page) => initializeImports(page, baseDir, basepath))
    .flat(1000);
  const routerProps = Object.assign({}, basepath && { basepath });
  // console.log(routes);
  return (
    <Suspense fallback={<Loading />}>
      <Router primary={false} {...routerProps}>
        {routes}
      </Router>
    </Suspense>
  );
};

export const initializeEntry = (
  pages: RouteInterface[],
  baseDir = "./"
): InitializeEntry => {
  const publicRoutes: FlatArray<FunctionComponentElement<any>[], 1000>[][] = [];
  const privateRoutes: FlatArray<FunctionComponentElement<any>[], 1000>[][] =
    [];

  for (const page of pages) {
    if (page.private) {
      privateRoutes.push(initializeImports(page, baseDir));
    }
    if (!page.private) {
      publicRoutes.push(initializeImports(page, baseDir));
    }
  }
  return {
    public: publicRoutes.flat(1000),
    private: privateRoutes.flat(1000),
  };
};
