import { ActionOption, RootState } from 'src/redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnouncement, getUserAnnouncements } from 'src/redux/actions';
import { get as LodashGet } from 'lodash';
import { setCurrentAnnouncement } from 'src/redux/actions/announcements/setCurrentAnnouncement';

export type UseAnnouncementsProps = {
  initialOptions?: Record<string, any>;
};

export interface UseAnnouncementType {
  readonly setCurrent: (
    announcement: ClassesNamespace.Announcements | object,
    key: string
  ) => void;
  readonly findOne: (_id: string, key: string, options?: ActionOption) => void;
  readonly findUserAnnouncements: (key: string, options?: ActionOption) => void;
  readonly get: (key: string) => {
    readonly current?: ClassesNamespace.Announcements | null;
    readonly userAnnouncements?: ClassesNamespace.Announcements[];
  };
  readonly metadata: (key: string) => {
    loading: boolean;
    pagination: Record<string, any>;
  };
}

export const useAnnouncements = (): UseAnnouncementType => {
  // const { initialOptions } = props ?? {};
  const dispatch = useDispatch();

  const { currentAnnouncements, userAnnouncements, uiLoaders, pagination } =
    useSelector(({ announcements, ui }: RootState) => ({
      currentAnnouncements: announcements?.currentAnnouncements,
      userAnnouncements: announcements?.userAnnouncements,
      uiLoaders: ui?.uiLoaders,
      pagination: ui?.pagination,
    }));

  const findOne = (id: string, key: string, options?: ActionOption) => {
    dispatch(getAnnouncement(id, { ...options, key }));
  };

  const findUserAnnouncements = (key: string, options?: ActionOption) => {
    dispatch(getUserAnnouncements({ ...options, key }));
  };

  const setCurrent = (
    announcement: ClassesNamespace.Announcements | object,
    key: string
  ) => {
    dispatch(setCurrentAnnouncement(announcement, key));
  };

  const get = (key: string) => {
    return {
      current: LodashGet<
        Record<string, ClassesNamespace.Announcements>,
        string
      >(currentAnnouncements, key),
      userAnnouncements:
        LodashGet<Record<string, ClassesNamespace.Announcements[]>, string>(
          userAnnouncements,
          key
        ) || [],
    };
  };

  const metadata = (key: string) => ({
    loading: LodashGet(uiLoaders, key, false),
    pagination: LodashGet(pagination, key, {}),
  });

  //  useEffect(() => {
  //   if (initialKey) {
  //     findAll(initialKey, Object.assign({}, initialOptions));
  //   }
  // }, [classId]);

  return {
    findOne,
    findUserAnnouncements,
    get,
    metadata,
    setCurrent,
  };
};
