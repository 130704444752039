import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const UPDATE_COMMENTS = createActionType('UPDATE_COMMENTS', 'COMMENTS');

export const updateComments = (
  commentId: string,
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: UPDATE_COMMENTS.START,
  meta: {
    commentId,
    payload,
    ...option,
  },
});
