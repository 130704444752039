import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_USER_CLASSES = createActionType(
  'FIND_USER_CLASSES',
  'CLASSES'
);

export const findUserClasses = (option: ActionOption) => ({
  type: FIND_USER_CLASSES.START,
  meta: {
    ...option,
  },
});

export default findUserClasses;
