import { getLesson, getTodayLesson } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { selectTodayLesson } from '../../selectors/lesson';

export type UseLessonProps = {
  key: string;
  userId?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type UseLessonType = {
  handleFetchTodayLesson: (options?: ActionOption) => void;
  handleFetchOne: (_id: string, options?: ActionOption) => void;
  currentLesson: LessonNameSpace.Lesson;
  todayLesson: LessonNameSpace.Lesson[];
};

export const useLesson = ({
  params,
  key,
  doFind,
}: UseLessonProps): UseLessonType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
  };

  const { currentLesson, todayLesson } = useSelector(
    ({ lesson }: RootState) => ({
      currentLesson: lesson?.currentLesson[key],
      todayLesson: selectTodayLesson(lesson, key),
    })
  );

  const handleFetchOne = (id: string, options?: ActionOption) => {
    dispatch(getLesson(id, { ...defaultOptions, ...options }));
  };

  const handleFetchTodayLesson = (options?: ActionOption) => {
    dispatch(getTodayLesson({ ...defaultOptions, ...options }));
  };

  return {
    handleFetchOne,
    handleFetchTodayLesson,
    currentLesson,
    todayLesson,
  };
};
