import { useEffect } from 'react';
import { findAttendances } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';

export type UseAttendanceProps = {
  key: string;
  classId?: string;
  params?: Record<string, any> | any;
  doFind?: boolean;
};

export type UseAttendanceType = {
  attendances: ClassesNamespace.Attendance[];
  handleFetch: (options?: ActionOption) => void;
};

export const useAttendance = ({
  key,
  classId,
  params,
  doFind = true,
}: UseAttendanceProps): UseAttendanceType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
  };

  const { attendances } = useSelector(({ attendances }: RootState) => ({
    attendances: attendances?.attendances[key],
  }));

  useEffect(() => {
    if (doFind) {
      dispatch(
        findAttendances({
          params,
          key,
        })
      );
    }
  }, [doFind, classId]);

  const handleFetch = (options?: ActionOption) => {
    dispatch(findAttendances({ ...defaultOptions, ...options }));
  };

  return {
    attendances,
    handleFetch,
  };
};
