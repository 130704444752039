import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';

export const FIND_ACTIVITIES = createActionType('FIND_ACTIVITIES', 'ACTIVITY');

export const findActivity = (options?: ActionOption) => ({
  type: FIND_ACTIVITIES.START,
  meta: {
    ...options
  }
});
