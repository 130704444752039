import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {GET, Services, ServicesURLs} from 'src/_shared/constants';
import {apiRequest} from '../../../actions/app';
import {COMPLETE_ACTIVITY_SUBMISSION} from '../../../actions';

export const completeActivitySubmission: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === COMPLETE_ACTIVITY_SUBMISSION.START) {
      const { key, _id, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.activities}/${_id}/completed`,
          key: key || 'completeActivitySubmission',
            ...rest,
          soundType: 'ambient',
          successMessage: 'Activity completed!',
          useSound: true,
          onSuccess: (data: any) => {
            dispatch({
              type: COMPLETE_ACTIVITY_SUBMISSION.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
