import {createActionType} from 'src/_shared/utils';
import {ActionOption} from '../../../types';

export const SUBMIT_ACTIVITY_QUESTION = createActionType(
  'SUBMIT_ACTIVITY_QUESTION',
  'ACTIVITY'
);

export const submitActivityQuestion = (
  _id: string,
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: SUBMIT_ACTIVITY_QUESTION.START,
  meta: {
    _id,
    payload,
    ...option,
  },
});
