import { FIND_CLASS_ANNOUNCEMENTS } from '../../actions';
import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';

export interface ClassAnnouncementsState {
  announcements: Record<string, ClassesNamespace.Announcements[]>;
  announcementById: Record<
    string,
    Record<string, ClassesNamespace.Announcements>
  >;
}

export const ClassAnnouncementsStateDefaultState: ClassAnnouncementsState = {
  announcements: {},
  announcementById: {},
};

const classAnnouncementReducer = (
  state = ClassAnnouncementsStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_CLASS_ANNOUNCEMENTS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        announcementById: {
          ...state.announcementById,
          [action.key]: byIdFind,
        },
        announcements: {
          ...state.announcements,
          [action.key]: Object.values(byIdFind),
        },
      });
    }
    default:
      return state;
  }
};

export default classAnnouncementReducer;
