import { createSelector } from 'reselect';
import { SyllabusState } from '../../redux/reducers/syllabus';
import { pick } from 'lodash';

const Syllabus = (syllabus: SyllabusState, key: string) =>
  syllabus?.syllabus[key] || [];

export const selectSyllabus = createSelector(
  Syllabus,
  (syllabus: ClassesNamespace.Syllabus[]) => {
    return syllabus.map((syllabus: ClassesNamespace.Syllabus) => {
      return {
        ...pick(syllabus, ['title', 'lessons']),
      };
    });
  }
);
