import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { PUT, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { UPDATE_COMMENTS } from '../../../actions';

export const updateComments: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === UPDATE_COMMENTS.START) {
      const { key, uiKey, commentId, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.communication,
          method: PUT,
          url: `${ServicesURLs.comments}/${commentId}`,
          key: uiKey || 'updateComments',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: UPDATE_COMMENTS.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
