import { Action } from '../../types';
import { FIND_ATTENDANCES } from '../../actions';
import { arrayToById } from 'src/_shared/utils';

export interface AttendanceState {
  attendances: ClassesNamespace.Attendance[] | any;
  attendancesById: Record<string, ClassesNamespace.Attendance[]> | any;
}

export const AttendanceStateDefaultState: AttendanceState = {
  attendances: {},
  attendancesById: {},
};

const attendanceReducer = (
  state = AttendanceStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_ATTENDANCES.SUCCESS: {
      const byIdFind = arrayToById(payload || []);

      return Object.assign({}, state, {
        attendancesById: {
          ...state.attendancesById,
          [action.key]: byIdFind,
        },
        attendances: {
          ...state.attendances,
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default attendanceReducer;
