import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {POST, Services, ServicesURLs} from 'src/_shared/constants';
import {apiRequest} from '../../../actions/app';
import {SUBMIT_ACTIVITY_QUESTION} from '../../../actions';

export const submitActivityQuestion: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === SUBMIT_ACTIVITY_QUESTION.START) {
      const { key, _id, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: POST,
          url: `${ServicesURLs.activities}/${_id}/submit`,
          key: key || 'submitActivityQuestion',
          soundType: 'hero',
          successMessage: 'Answer saved!',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: SUBMIT_ACTIVITY_QUESTION.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
