import app from './app';
import auth from './auth';
import classMembers from './classMember';
import classAnnouncements from './classAnnouncements';
import syllabus from './syllabus';
import comments from './comments';
import announcements from './announcements';
import activity from './activity';
import classes from './classes';
import grade from './grade';
import attendance from './attendance';
import lesson from './lesson';
import notifications from './notifications';
import publicRoutes from './public';

export default [
  ...app,
  ...auth,
  ...classMembers,
  ...classAnnouncements,
  ...syllabus,
  ...comments,
  ...announcements,
  ...activity,
  ...classes,
  ...grade,
  ...attendance,
  ...lesson,
  ...notifications,
  ...publicRoutes,
];
