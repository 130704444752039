import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_CLASS_ANNOUNCEMENTS = createActionType(
  'FIND_CLASS_ANNOUNCEMENTS',
  'ANNOUNCEMENTS'
);

export const findClassAnnouncements = (
  classId: string,
  option?: ActionOption
) => ({
  type: FIND_CLASS_ANNOUNCEMENTS.START,
  meta: {
    classId,
    ...option,
  },
});
