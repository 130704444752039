import { Action } from '../../types';
import { GET_LESSON, GET_TODAY_LESSON } from '../../actions';

export interface LessonState {
  currentLesson: LessonNameSpace.Lesson | any;
  todayLesson: LessonNameSpace.Lesson | any;
}

export const LessonStateDefaultState: LessonState = {
  currentLesson: {},
  todayLesson: {},
};

const lessonReducer = (state = LessonStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_LESSON.SUCCESS: {
      return Object.assign({}, state, {
        currentLesson: {
          ...state.currentLesson,
          [action.key]: payload,
        },
      });
    }
    case GET_TODAY_LESSON.SUCCESS: {
      return Object.assign({}, state, {
        todayLesson: {
          ...state.todayLesson,
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default lessonReducer;
