import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';

export const GET_LINK_METADATA = createActionType(
  'GET_LINK_METADATA',
  'PUBLIC'
);

export const getLinkMeta = (link: string, option?: ActionOption) => ({
  type: GET_LINK_METADATA.START,
  meta: {
    ...option,
    payload: {
      link,
    },
  },
});
