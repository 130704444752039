import { apiRequest, FIND_CLASS_MEMBERS } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const findClassMember: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_CLASS_MEMBERS.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: ServicesURLs.classMembers,
          key: key || 'findClassMember',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: FIND_CLASS_MEMBERS.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
