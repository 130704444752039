import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { FIND_GRADE } from '../../../actions/grade/findGrade';

export const findGrade: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_GRADE.START) {
      const { key, params } = action.meta;
      const { classId, ...rest } = params;
      dispatch(
        apiRequest({
          service: Services.classroom,
          method: GET,
          url: `${ServicesURLs.classes}/${classId}${ServicesURLs.gradeBook}`,
          key: key || 'findGrade',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: FIND_GRADE.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
