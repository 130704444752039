import {FIND_CLASS_MEMBERS} from '../../actions';
import {Action} from '../../types';
import {arrayToById} from '../../../_shared/utils';

export interface ClassMemberState {
  classMembers: Record<string, LessonNameSpace.ClassMember[]>;
  classMembersById: Record<string, Record<string, LessonNameSpace.ClassMember>>;
}

export const ClassMemberStateDefaultState: ClassMemberState = {
  classMembers: {},
  classMembersById: {}
};

const classMemberReducer = (state = ClassMemberStateDefaultState, action: Action) => {
  const {payload} = action;
  switch (action.type) {
    case FIND_CLASS_MEMBERS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        classMembersById: {
          ...state.classMembersById,
          [action.key]: byIdFind
        },
        classMembers: {
          ...state.classMembers,
          [action.key]: Object.values(byIdFind)
        }
      });
    }
    default:
      return state;
  }
};


export default classMemberReducer;
