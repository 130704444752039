import { Action } from '../../types';
import {
  GET_AUTH_USER,
  LOGOUT,
  RESET_APP_STATE,
  GET_USERS,
} from '../../actions';

export type AppState = {
  auth: any | null;
  users: any | null;
};
export const defaultState: AppState = {
  auth: null,
  users: {},
};

const appReducer = (state = defaultState, action: Action): AppState => {
  switch (action.type) {
    case RESET_APP_STATE:
      return { ...state, ...defaultState };
    case GET_AUTH_USER.SUCCESS:
      return { ...state, auth: action.payload };
    case GET_USERS.SUCCESS:
      return {
        ...state,
        users: { ...state.users, [action.key]: action.payload },
      };
    case LOGOUT:
      return defaultState;
    default:
      return state;
  }
};

export default appReducer;
