import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';

export const GET_ANNOUNCEMENT = createActionType(
  'GET_ANNOUNCEMENT',
  'ANNOUNCEMENTS'
);

export const getAnnouncement = (_id: string, option?: ActionOption) => ({
  type: GET_ANNOUNCEMENT.START,
  meta: {
    _id,
    ...option,
  },
});
