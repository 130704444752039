import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from "../../../types";

export const FIND_CLASS_MEMBERS = createActionType(
  'FIND_CLASS_MEMBERS',
  'LESSON'
);

export const findClassMember = (option: ActionOption) => ({
  type: FIND_CLASS_MEMBERS.START,
  meta: {
    ...option
  },
});

export default findClassMember;
