import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';

export const GET_QUESTION_SUBMISSION = createActionType(
  'GET_QUESTION_SUBMISSION',
  'ACTIVITY'
);

export const getQuestionSubmissions = (option?: ActionOption) => ({
  type: GET_QUESTION_SUBMISSION.START,
  meta: {
    ...option,
  },
});
