import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {GET, Services, ServicesURLs} from '../../../../_shared/constants';
import {apiRequest} from '../../../actions/app';
import {FIND_ACTIVITY_SUBMISSIONS} from '../../../actions';

export const findActivitySubmissions: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_ACTIVITY_SUBMISSIONS.START) {
      const { key, _id, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.activitySubmissions}`,
          key: key || 'findActivitySubmissions',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: FIND_ACTIVITY_SUBMISSIONS.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
