import { getUsers } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { get } from 'lodash';

export type UseUsersType = {
  users: AuthNameSpace.User[];
  getUsers: (options?: ActionOption) => void;
};

interface UseUsers {
  key?: string;
}
export const useUsers = (props?: UseUsers): UseUsersType => {
  const { key = 'getUsers' } = props ?? {};
  const dispatch = useDispatch();

  const users = useSelector(
    ({ app }: RootState) => get(app, ['users', key]) ?? []
  );

  const $getUsers = (options?: ActionOption) => {
    dispatch(getUsers({ key, ...options }));
  };

  return {
    users,
    getUsers: $getUsers,
  };
};
