import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { POST, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { POST_COMMENTS } from '../../../actions';

export const postComments: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === POST_COMMENTS.START) {
      const { key, uiKey, referenceId, commentType, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.communication,
          method: POST,
          url: `${ServicesURLs.comments}/${commentType}/${referenceId}`,
          key: uiKey || 'postComments',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: POST_COMMENTS.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
