import {
  FIND_COMMENTS,
  POST_COMMENTS,
  UPDATE_COMMENTS,
  DELETE_COMMENTS,
} from '../../actions';
import { Action } from '../../types';
import { arrayToById } from 'src/_shared/utils';
import { get } from 'lodash';

export interface CommentsState {
  comments: Record<string, ClassesNamespace.Comments[]>;
  commentsById: Record<string, Record<string, ClassesNamespace.Comments>>;
}

export const CommentsStateDefaultState: CommentsState = {
  comments: {},
  commentsById: {},
};

const commentsReducer = (state = CommentsStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_COMMENTS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        commentsById: {
          ...state.commentsById,
          [action.key]: byIdFind,
        },
        comments: {
          ...state.comments,
          [action.key]: Object.values(byIdFind),
        },
      });
    }
    case POST_COMMENTS.SUCCESS: {
      let newComments = [];
      if (payload.parent) {
        const index = get(state.comments, `${action.key}`).findIndex(
          (comment: ClassesNamespace.Comments) => comment._id === payload.parent
        );
        const specificComment = get(state.comments, `${action.key}`, [])[index];
        specificComment?.replies?.push(payload);
        newComments = get(state.comments, `${action.key}`, []);
        if (specificComment) {
          newComments[index] = specificComment;
        }
      } else {
        newComments = get(state.comments, `${action.key}`);
        newComments.unshift(payload);
      }

      const byIdFind = arrayToById(newComments || []);

      return Object.assign({}, state, {
        commentsById: {
          ...state.commentsById,
          [action.key]: byIdFind,
        },
        comments: {
          ...state.comments,
          [action.key]: newComments,
        },
      });
    }
    case UPDATE_COMMENTS.SUCCESS: {
      let newComments = [];
      if (payload.parent) {
        const index = get(state.comments, `${action.key}`).findIndex(
          (comment: ClassesNamespace.Comments) => comment._id === payload.parent
        );
        const specificComment = get(state.comments, `${action.key}`, [])[index];
        const specificReplyIndex = specificComment?.replies?.findIndex(
          (reply: any) => reply._id === payload._id
        );
        if (
          typeof specificReplyIndex === 'number' &&
          specificReplyIndex !== -1 &&
          specificComment?.replies
        ) {
          specificComment.replies[specificReplyIndex] = payload;
        }
        newComments = [...get(state.comments, `${action.key}`)];
        if (specificComment) {
          newComments[index] = specificComment;
        }
      } else {
        const index = get(state.comments, `${action.key}`, []).findIndex(
          (comment: ClassesNamespace.Comments) => comment._id === payload._id
        );
        newComments = get(state.comments, `${action.key}`, []);
        newComments[index] = payload;
      }

      const byIdFind = arrayToById(newComments || []);

      return Object.assign({}, state, {
        commentsById: {
          ...state.commentsById,
          [action.key]: byIdFind,
        },
        comments: {
          ...state.comments,
          [action.key]: newComments,
        },
      });
    }
    case DELETE_COMMENTS.SUCCESS:
    default:
      return state;
  }
};

export default commentsReducer;
