import {FIND_USER_CLASSES, GET_LIVE_CLASS} from '../../actions';
import {Action} from '../../types';
import {arrayToById} from '../../../_shared/utils';

export interface ClassesState {
  userClasses: Record<string, ClassesNamespace.Class[]>;
  userClassesById: Record<string, Record<string, ClassesNamespace.Class>>;
  liveClass: Record<string, ClassesNamespace.LiveClass[]>;
  liveClassById: Record<string, Record<string, ClassesNamespace.LiveClass>>;
}

export const ClassesStateDefaultState: ClassesState = {
  userClasses: {},
  userClassesById: {},
  liveClass: {},
  liveClassById: {},
};

const classesReducer = (state = ClassesStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_USER_CLASSES.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        userClassesById: {
          ...state.userClassesById,
          [action.key]: byIdFind,
        },
        userClasses: {
          ...state.userClasses,
          [action.key]: Object.values(byIdFind),
        },
      });
    }
    case GET_LIVE_CLASS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        liveClassById: {
          ...state.liveClassById,
          [action.key]: byIdFind,
        },
        liveClass: {
          ...state.liveClass,
          [action.key]: Object.values(byIdFind),
        },
      });
    }
    default:
      return state;
  }
};

export default classesReducer;
