import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_ACTIVITIES_BY_PRACTICE = createActionType(
  'FIND_ACTIVITIES_BY_PRACTICE',
  'ACTIVITY'
);

export const findActivityByPractice = (
  classId: string,
  options?: ActionOption
) => ({
  type: FIND_ACTIVITIES_BY_PRACTICE.START,
  meta: {
    classId,
    ...options,
  },
});
