import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const FIND_NOTIFICATIONS = createActionType(
  'FIND_NOTIFICATIONS',
  'NOTIFICATION'
);

export const findNotifications = (options?: ActionOption) => ({
  type: FIND_NOTIFICATIONS.START,
  meta: {
    ...options,
  },
});
