import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const STUDENT_ACTIVITY_SUBMISSIONS = createActionType(
  'STUDENT_ACTIVITY_SUBMISSIONS',
  'ACTIVITY'
);

export const studentActivitySubmissions = (option?: ActionOption) => ({
  type: STUDENT_ACTIVITY_SUBMISSIONS.START,
  meta: {
    ...option,
  },
});
