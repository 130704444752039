import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_ACTIVITY_BY_PRACTICE = createActionType(
  'GET_ACTIVITY_BY_PRACTICE',
  'ACTIVITY'
);

export const getActivityByPractice = (
  _id: string,
  classId: string,
  option?: ActionOption
) => ({
  type: GET_ACTIVITY_BY_PRACTICE.START,
  meta: {
    _id,
    classId,
    ...option,
  },
});
