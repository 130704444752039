import { createActionType } from 'src/_shared/utils';
import { ActionOption } from 'src/redux/types';

export const FIND_ATTENDANCES = createActionType(
  'FIND_ATTENDANCES',
  'ATTENDANCE'
);

export const findAttendances = (option?: ActionOption) => ({
  type: FIND_ATTENDANCES.START,
  meta: {
    ...option,
  },
});
