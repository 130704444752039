import { createActionString } from '../../../../_shared/utils';
import {ReactNode} from "react";

export const UI_ERROR = createActionString('UI_ERROR', 'UI');

export const updateUIError = (key: string, value: string | null | ReactNode) => ({
  type: UI_ERROR,
  key,
  value,
});

export default updateUIError;
