import {createActionType} from 'src/_shared/utils';
import {ActionOption} from '../../../types';

export const COMPLETE_ACTIVITY_SUBMISSION = createActionType(
  'COMPLETE_ACTIVITY_SUBMISSION',
  'ACTIVITY'
);

export const completeActivitySubmission = (
  _id: string,
  option?: ActionOption
) => ({
  type: COMPLETE_ACTIVITY_SUBMISSION.START,
  meta: {
    _id,
    ...option,
  },
});
