import { Action } from '../../types';
import { FIND_NOTIFICATIONS } from '../../actions';
import { arrayToById } from '../../../_shared/utils';

export interface NotificationState {
  notifications: Record<string, Record<string, any>[]>;
  notificationsById: Record<string, any>;
}

export const NotificationDefaultState: NotificationState = {
  notifications: {},
  notificationsById: {},
};

const notificationReducer = (
  state = NotificationDefaultState,
  action: Action
) => {
  const { payload, key } = action;
  switch (action.type) {
    case FIND_NOTIFICATIONS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        notifications: {
          ...state.notifications,
          [key]: Object.values(byIdFind),
        },
        notificationsById: {
          ...state.notificationsById,
          [key]: byIdFind,
        },
      });
    }
    default:
      return state;
  }
};

export default notificationReducer;
