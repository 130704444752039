import { ActionOption, RootState } from '../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { findClassAnnouncements } from '../../redux/actions';
import { get as LodashGet } from 'lodash';
import { useEffect } from 'react';

export type UseClassAnnouncementsProps = {
  classId: string;
  initialKey?: string;
  initialOptions?: Record<string, any>;
};

export interface UseClassAnnouncementType {
  readonly findAll: (
    key: string,
    classId: string,
    options?: ActionOption
  ) => void;
  readonly get: (key: string) => {
    readonly announcementList: ClassesNamespace.Announcements[];
    readonly announcementsById: Record<string, ClassesNamespace.Announcements>;
  };
  readonly metadata: (key: string) => {
    loading: boolean;
    pagination: Record<string, any>;
  };
}

export const useClassAnnouncements = (
  props: UseClassAnnouncementsProps
): UseClassAnnouncementType => {
  const { initialKey, initialOptions, classId } = props ?? {};
  const dispatch = useDispatch();


  const { classAnnouncements, classAnnouncementsById, uiLoaders, pagination } =
    useSelector(({ classAnnouncements, ui }: RootState) => ({
      classAnnouncements: classAnnouncements?.announcements,
      classAnnouncementsById: classAnnouncements?.announcementById,
      uiLoaders: ui?.uiLoaders,
      pagination: ui?.pagination,
    }));

  const findAll = (key: string, classId: string, options?: ActionOption) => {
    dispatch(findClassAnnouncements(classId, { ...options, key }));
  };

  const get = (key: string) => {
    return {
      announcementList: LodashGet<
        Record<string, ClassesNamespace.Announcements[]>,
        string,
        ClassesNamespace.Announcements[]
      >(classAnnouncements, key, []),
      announcementsById: LodashGet<
        Record<string, Record<string, ClassesNamespace.Announcements>>,
        string,
        Record<string, ClassesNamespace.Announcements>
      >(classAnnouncementsById, key, {}),
    };
  };
  const metadata = (key: string) => ({
    loading: LodashGet(uiLoaders, key, false),
    pagination: LodashGet(pagination, key, {}),
  });

  useEffect(() => {
    if (initialKey) {
      findAll(initialKey, classId, Object.assign({}, initialOptions));
    }
  }, [classId]);

  return {
    findAll,
    get,
    metadata,
  };
};
