import { apiRequest, FIND_ATTENDANCES } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const findAttendances: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_ATTENDANCES.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.attendance}/register`,
          key: key || 'findAttendances',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: FIND_ATTENDANCES.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
