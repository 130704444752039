import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const GET_USER_ANNOUNCEMENTS = createActionType(
  'GET_USER_ANNOUNCEMENTS',
  'ANNOUNCEMENTS'
);

export const getUserAnnouncements = (option?: ActionOption) => ({
  type: GET_USER_ANNOUNCEMENTS.START,
  meta: {
    ...option,
  },
});
