import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';
import { FIND_GRADE } from '../../actions/grade/findGrade';

export interface GradeState {
  grade: Record<string, ClassesNamespace.Grade[]> | any;
  gradeById: Record<string, Record<string, ClassesNamespace.Grade>> | any;
}

export const GradeDefaultState: GradeState = {
  grade: {},
  gradeById: {},
};

const gradeReducer = (state = GradeDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_GRADE.SUCCESS: {
      console.log('payload::::', payload);
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        grade: {
          ...state.grade,
          [action.key]: Object.values(byIdFind),
        },
        gradeById: {
          ...state.gradeById,
          [action.key]: byIdFind,
        },
      });
    }

    default:
      return state;
  }
};

export default gradeReducer;
