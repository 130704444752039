import { apiRequest, READ_NOTIFICATIONS } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { PUT, Services } from '../../../../_shared/constants';

export const readNotification: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === READ_NOTIFICATIONS.START) {
      const { key, params, ...rest } = action.meta;
      const { notificationId } = params;
      dispatch(
        apiRequest({
          service: Services.auth,
          method: PUT,
          url: `/notifications/${notificationId}`,
          key: key || 'readNotification',
          noSuccessMessage: true,
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: READ_NOTIFICATIONS.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
