import { CreateActionType } from '../../_shared/utils';
import { RequestMethod } from '../actions/app';
import { isString, isObject } from 'lodash';

export type HybridType =
  | Record<string, any>
  | Array<Record<string, any>>
  | string
  | string[]
  | number
  | number[]
  | boolean
  | boolean[]
  | null
  | undefined;

export interface ActionFactory extends Record<string, any> {
  /**
   * REST API request method
   */
  method?: RequestMethod;
  /**
   * Request URL
   */
  url?: string;
  /**
   * Request Key (identification, used to handle loading state and error state)
   */
  key?: string;
  /**
   * Fired after an API request is success full
   * @param data
   */
  onSuccess?: (data?: HybridType) => void | string;
  /**
   * Fired after a request is successful
   * @param data
   */
  onComplete?: (data?: HybridType) => void | string;
  /**
   * Fire when a request starts
   */
  onStart?: () => void | string;
  /**
   * Fired when a request ends
   */
  onEnd?: () => void | string;
  /**
   * Fired when the request fails
   * @param error
   */
  onError?: (error: HybridType) => void | string;
  /**
   * URL query params
   */
  params?: Record<string, any>;
  /**
   * Payload to send to API usually in a PUT | PATCH | POST request
   */
  payload?: HybridType;
  /**
   * Custom message for when a request fails
   * @param error
   */
  errorMessage?: ((error?: HybridType) => string) | string;
  /**
   * Custom success message for when a request is successful
   */
  successMessage?: ((data?: HybridType) => string) | string;
  /**
   * Tells Middleware to return both data and meta properties for a request
   */
  metadata?: boolean;
}

export interface Action extends Record<string, any> {
  type: string;
  meta: ActionFactory;
}

export const ActionCreator = (type: string | CreateActionType) => {
  const action: Partial<Action> = {};
  if (isString(type)) {
    action.type = type;
    action._actionType = type;
  }
  if (isObject(type)) {
    action.type = type.START;
    action._actionType = type;
  }
  return (actionableObject: ActionFactory): Action => {
    action.meta = actionableObject;
    return action as Action;
  };
};
