import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const DELETE_COMMENTS = createActionType('DELETE_COMMENTS', 'COMMENTS');

export const deleteComments = (commentId: string, option?: ActionOption) => ({
  type: DELETE_COMMENTS.START,
  meta: {
    commentId,
    ...option,
  },
});
