import { createSelector } from 'reselect';
import { LessonState } from '../../redux/reducers/lesson';
import { capitalize } from 'lodash';

const todayLesson = (lesson: LessonState, key: string) =>
  lesson?.todayLesson[key] || [];

export const selectTodayLesson = createSelector(
  todayLesson,
  (todayLesson: LessonNameSpace.Lesson[]) => todayLesson
);
