import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';

export const GET_LIVE_CLASS = createActionType(
    'GET_LIVE_CLASS',
    'CLASSES'
);

export const getLiveClass = (option: ActionOption) => ({
    type: GET_LIVE_CLASS.START,
    meta: {
        ...option,
    },
});

export default getLiveClass;
