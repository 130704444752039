import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const READ_NOTIFICATIONS = createActionType(
  'READ_NOTIFICATIONS',
  'NOTIFICATION'
);

export const readNotifications = (
  payload: Record<string, any>,
  options?: ActionOption
) => ({
  type: READ_NOTIFICATIONS.START,
  meta: {
    payload,
    ...options,
  },
});
