import { createActionType } from '../../../../_shared/utils';
import { ActionOption } from '../../../types';

export const POST_COMMENTS = createActionType('POST_COMMENTS', 'COMMENTS');

export const postComments = (
  referenceId: string,
  commentType: string,
  payload: Record<string, any>,
  option?: ActionOption
) => ({
  type: POST_COMMENTS.START,
  meta: {
    referenceId,
    commentType,
    payload,
    ...option,
  },
});
