import {ActionOption} from '../../redux/types';
import {useState} from 'react';
import {getLinkMeta} from '../../redux/actions';
import {useDispatch} from 'react-redux';

interface UseLinkMetadataParams {
  options?: ActionOption;
}
interface UseLinkMetadata {
  getLinkMeta: (link: string) => void;
  metadata: PublicNamespace.LinkMetadata | null;
}

export const useLinkMetadata = (
  props?: UseLinkMetadataParams
): UseLinkMetadata => {
  const [linkMeta, setLinkMeta] = useState<PublicNamespace.LinkMetadata | null>(
    null
  );

  const dispatch = useDispatch();

  const $getLinkMeta = (link: string) => {
    dispatch(
      getLinkMeta(link, {
        ...(props?.options ?? {}),
        onFinish(data: Record<string, any>) {
          setLinkMeta(data as PublicNamespace.LinkMetadata);
        },
      })
    );
  };

  return {
    metadata: linkMeta,
    getLinkMeta: $getLinkMeta,
  };
};
