import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import App from './app/App';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import './index.scss';
import {NetworkOverlay} from './app/layout/network-overlay';
import {AuthProvider} from './contexts';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'remixicon/fonts/remixicon.css';
import 'react-quill/dist/quill.snow.css';
// import 'video-react/dist/video-react.css';
import 'src/app/assets/styles/stm-theme.min.css';


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS_URL,
  attachStacktrace: ['production', 'development'].includes(
    process.env.NODE_ENV
  ),
  debug: process.env.NODE_ENV !== 'production',
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NetworkOverlay>
          <AuthProvider>
            <App />
          </AuthProvider>
        </NetworkOverlay>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
