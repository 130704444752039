import { Action } from '../../types';
import { arrayToById } from '../../../_shared/utils';
import { FIND_SYLLABUS } from '../../actions/syllabus/findSyllabus';

export interface SyllabusState {
  syllabus: Record<string, ClassesNamespace.Syllabus[]> | any;
  syllabusById: Record<string, Record<string, ClassesNamespace.Syllabus>> | any;
  currentSyllabus: ClassesNamespace.Syllabus | any;
}

export const SyllabusDefaultState: SyllabusState = {
  syllabus: {},
  syllabusById: {},
  currentSyllabus: {},
};

const syllabusReducer = (state = SyllabusDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_SYLLABUS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        syllabus: {
          ...state.syllabus,
          [action.key]: Object.values(byIdFind),
        },
        syllabusById: {
          ...state.syllabusById,
          [action.key]: byIdFind,
        },
      });
    }

    default:
      return state;
  }
};

export default syllabusReducer;
