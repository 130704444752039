import {
  FIND_ACTIVITIES,
  FIND_ACTIVITIES_BY_CLASS,
  FIND_ACTIVITIES_BY_PRACTICE,
  FIND_ACTIVITY_SUBMISSIONS,
  STUDENT_ACTIVITY_SUBMISSIONS,
  GET_ACTIVITY,
  GET_QUESTION_SUBMISSION,
} from 'src/redux/actions';
import { Action } from 'src/redux/types';
import { arrayToById } from 'src/_shared/utils';

export interface ActivityState {
  activities: Record<string, LessonNameSpace.Activity[]>;
  activitiesById: Record<string, Record<string, LessonNameSpace.Activity>>;
  currentActivity: LessonNameSpace.Activity | any;
  questionSubmission: Record<string, LessonNameSpace.QuestionSubmission[]>;
  activitySubmission: Record<string, LessonNameSpace.ActivitySubmission[]>;
  studentActivitySubmissions: Record<
    string,
    LessonNameSpace.ActivitySubmission[]
  >;
}

export const ActivityStateDefaultState: ActivityState = {
  activities: {},
  activitiesById: {},
  currentActivity: {},
  questionSubmission: {},
  activitySubmission: {},
  studentActivitySubmissions: {},
};

const activityReducer = (state = ActivityStateDefaultState, action: Action) => {
  const { payload } = action;
  switch (action.type) {
    case FIND_ACTIVITIES.SUCCESS:
    case FIND_ACTIVITIES_BY_PRACTICE.SUCCESS:
    case FIND_ACTIVITIES_BY_CLASS.SUCCESS: {
      const byIdFind = arrayToById(payload || []);
      return Object.assign({}, state, {
        activitiesById: {
          ...state.activitiesById,
          [action.key]: byIdFind,
        },
        activities: {
          ...state.activities,
          [action.key]: Object.values(byIdFind),
        },
        currentActivity: {
          ...state.currentActivity,
          [action.key]: {},
        },
      });
    }
    case GET_ACTIVITY.SUCCESS: {
      const byIdCreate = {
        ...state.activitiesById[action.key],
        [payload._id]: payload,
      };
      return Object.assign({}, state, {
        activitiesById: {
          ...state.activitiesById,
          [action.key]: byIdCreate,
        },
        activities: {
          ...state.activities,
          [action.key]: Object.values(byIdCreate),
        },
        currentActivity: {
          ...state.currentActivity,
          [action.key]: payload,
        },
      });
    }
    case GET_QUESTION_SUBMISSION.SUCCESS: {
      return {
        ...state,
        questionSubmission: {
          ...state.questionSubmission,
          [action.key]: payload,
        },
      };
    }
    case FIND_ACTIVITY_SUBMISSIONS.SUCCESS: {
      return {
        ...state,
        activitySubmission: {
          ...state.activitySubmission,
          [action.key]: payload,
        },
      };
    }
    case STUDENT_ACTIVITY_SUBMISSIONS.SUCCESS: {
      return {
        ...state,
        studentActivitySubmissions: {
          ...state.studentActivitySubmissions,
          [action.key]: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default activityReducer;
