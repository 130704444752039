import {createActionType} from '../../../../_shared/utils';
import {ActionOption} from '../../../types';


export const GET_LESSON = createActionType('GET_LESSON', 'LESSON');

export const getLesson = (_id: string, option?: ActionOption) => ({
  type: GET_LESSON.START,
  meta: {
    ...option,
    _id
  }
});
