import {apiRequest, REMOVE_DEVICE_FROM_CHANNEL} from '../../../actions';
import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {DELETE, Services} from '../../../../_shared/constants';

export const removeDeviceFromChannel: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === REMOVE_DEVICE_FROM_CHANNEL.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.communication,
          method: DELETE,
          url: `/devices`,
          key: key || 'removeDeviceFromChannel',
          ...rest,
          noSuccessMessage: true,
          onSuccess: (data: any) => {
            dispatch({
              type: REMOVE_DEVICE_FROM_CHANNEL.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
