import {apiRequest, GET_LIVE_CLASS} from '../../../actions';
import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {GET, Services, ServicesURLs} from '../../../../_shared/constants';

export const getLiveClass: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_LIVE_CLASS.START) {
      const { key, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.getLoggedInUser}/live-classes`,
          key: key || 'getLiveClass',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({ type: GET_LIVE_CLASS.SUCCESS, payload: data, key });
          },
        })
      );
    }
  };
