import {motion} from 'framer-motion';
import {Button} from 'antd';
import ErrorCoffeeSpilled from 'src/app/assets/images/Error_Coffee_Spilled.gif';

interface RedirectAccountProps {
  userType?: string;
}
export const RedirectAccount = (props: RedirectAccountProps) => {
  const { userType } = props;

  const onLogin = () => {
    window.location.assign(
      `${process.env.REACT_APP_AUTH_SERVICE_URL}?redirectUrl=${window.location.href}`
    );
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ height: '100vh', width: '100vw' }}
      className={
        'stm-bg-primary stm-flex stm-items-center stm-justify-center stm-flex-col'
      }
      data-theme={'light-content'}
    >
      <div className="stm-flex stm-items-center stm-justify-center w-100 stm-pb-3">
        <img
          src={ErrorCoffeeSpilled}
          height={300}
          width={300}
          style={{ userSelect: 'none' }}
        />
      </div>
      {userType && (
        <>
          <span className="stm-fs-text-5 stm-fw-3 stm-block">
            Oops! you're not authorized to login
          </span>
          <span className="stm-fs-text-3 stm-block stm-pv-4">
            Please login with a valid credential.
          </span>
        </>
      )}
      {!userType && (
        <>
          <span className="stm-fs-text-5 stm-fw-3 stm-block">
            You're not signed in!
          </span>
          <span className="stm-fs-text-3 stm-block stm-pv-4">
            Please login to access your account
          </span>
        </>
      )}

      <Button
        icon={
          <span className="anticon">
            <i className="ri-arrow-go-back-line" />
          </span>
        }
        type={'primary'}
        onClick={onLogin}
      >
        Login in
      </Button>
    </motion.div>
  );
};
