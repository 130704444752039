import {createContext, ReactNode, ReactNodeArray, useEffect} from 'react';
import {useAuth} from '../../hooks';
import {PROVIDER_KEYS} from '../../_shared/constants';
import {useClassMember} from '../../hooks/useClassMember';
import {RouteComponentProps} from '@reach/router';

export type ClassProviderStateType = {
  userClasses: LessonNameSpace.Class[];
  key: string;
};
const initialState: ClassProviderStateType = {
  userClasses: [],
  key: PROVIDER_KEYS.Class,
};

interface ClassProviderProps extends RouteComponentProps {
  children: ReactNode | ReactNodeArray;
}

export const ClassContext = createContext(initialState);

export const ClassProvider = ({ children }: ClassProviderProps) => {
  const { auth } = useAuth();
  const { userClasses, getClassMembers } = useClassMember({
    userId: auth?._id,
    key: PROVIDER_KEYS.Class,
    params: {
      all: true,
    },
    doFind: false,
  });

  const onGetClassMembers = () => {
    if (auth?._id) {
      getClassMembers({
        params: Object.assign(
          {},
          {
            user: auth?._id,
            population: JSON.stringify(['user', 'class']),
          }
        ),
      });
    }
  };

  useEffect(() => {
    if (auth?._id) {
      onGetClassMembers();
      const stealthUserEventListener = () => {
        onGetClassMembers();
      };

      window.addEventListener(
        PROVIDER_KEYS.StealthUser,
        stealthUserEventListener
      );

      return () => {
        window.removeEventListener(
          PROVIDER_KEYS.StealthUser,
          stealthUserEventListener
        );
      };
    }
  }, [auth?._id]);

  return (
    <ClassContext.Provider value={{ userClasses, key: PROVIDER_KEYS.Class }}>
      {children}
    </ClassContext.Provider>
  );
};

export default ClassProvider;
