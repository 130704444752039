const log = (name: string) => {
  return (...args: any[]) => {
    console.log(
      `%c${name.toUpperCase()}: `,
      'font-size: 18px; color: orange;',
      ...args
    );
  };
};

const logTable = (name: string) => {
  return (arg: any) => {
    console.log(`%c${name.toUpperCase()}: `, 'font-size: 18px; color: orange;');
    console.table(arg);
  };
};

export { log, logTable };
