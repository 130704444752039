import { combineReducers } from 'redux';
import app from './app';
import ui from './ui';
import classMember from './classMember';
import syllabus from './syllabus';
import grade from './grade';
import classAnnouncements from './classAnnouncements';
import comments from './comments';
import announcements from './announcements';
import activity from './activity';
import classes from './classes';
import attendances from './attendance';
import lesson from './lesson';
import notification from './notification';

const appReducers = combineReducers({
  app,
  ui,
  classMember,
  syllabus,
  classAnnouncements,
  announcements,
  comments,
  activity,
  classes,
  grade,
  attendances,
  lesson,
  notification
});

export default appReducers;
