import {useEffect, useMemo} from "react";
import {io} from "socket.io-client";
import {getToken} from "../../_shared/utils";
import {isFunction} from "lodash";

export const useSocketIO = (
    options: {
        listener?: (data: any) => void;
        event?: string;
    } = { event: 'message' }
) => {
    const socket = useMemo(() => {
        return io(`${process.env.REACT_APP_SOCKET_BASE_URL}?auth=${getToken()}`);
    }, [io]);

    useEffect(() => {
        socket.on('connect', () => {
            console.log(
                `%cSocket connected: ${socket.id}`,
                'color:#BADA55;font-size:16px'
            );
        });
        if (options?.listener && isFunction(options?.listener)) {
            socket.on(options?.event ?? 'message', options.listener);
        }
    }, [options.event]);

    return socket;
};