import { useEffect } from 'react';
import { findClassMember } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';
import { selectClassList, selectUserClasses } from '../../selectors';

export type UseClassMemberProps = {
  key: string;
  userId?: string;
  params?: Record<string, any>;
  doFind?: boolean;
};

export type UseClassMemberType = {
  classMembers: LessonNameSpace.ClassMember[];
  classList: Record<string, any>[];
  classesById: Record<string, LessonNameSpace.ClassMember>;
  userClasses: LessonNameSpace.Class[];
  getClassById: (classId: string) => LessonNameSpace.Class | undefined;
  getClassMembers: (options?: ActionOption) => void;
};

export const useClassMember = ({
  key,
  userId,
  params = {},
  doFind = true,
}: UseClassMemberProps): UseClassMemberType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    params: Object.assign(
      {},
      {
        user: userId,
        population: JSON.stringify(['user', 'class']),
      },
      params
    ),
  };
  const { classList, classMembers, userClasses, classesById } = useSelector(
    ({ classMember }: RootState) => ({
      classMember,
      userClasses: selectUserClasses(classMember, key),
      classList: selectClassList(classMember, key),
      classMembers: classMember?.classMembers[key] ?? [],
      classesById: classMember?.classMembersById[key] ?? {},
    })
  );

  useEffect(() => {
    if (doFind) {
      dispatch(
        findClassMember({
          ...defaultOptions,
        })
      );
    }
  }, [userId]);

  const getClassById = (classId: string) => {
    return userClasses.find((c: LessonNameSpace.Class) => c._id === classId);
  };

  const getClassMembers = (options: ActionOption = {}) => {
    dispatch(
      findClassMember({
        ...defaultOptions,
        ...options,
      })
    );
  };

  return {
    userClasses,
    classMembers,
    classList,
    classesById,
    getClassById,
    getClassMembers,
  };
};
