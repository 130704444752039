import { LOGOUT, RESET_APP_STATE } from '../../../actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import cookie from 'js-cookie';
import { Storage } from '../../../../_shared/storage';
import { PROVIDER_KEYS } from '../../../../_shared/constants';

export const logout: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === LOGOUT) {
      if (cookie.get('token')) {
        cookie.remove('token');
        const storage = new Storage(PROVIDER_KEYS.StealthUser, null);
        storage.set(null);
        dispatch({ type: RESET_APP_STATE });
        // window.location.assign(
        //   `${process.env.REACT_APP_AUTH_SERVICE_URL}?redirectUrl=${window.location.href}` as string
        // );
      }
    }
  };
