import {apiRequest, GET_USERS} from '../../../actions';
import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {GET, Services, ServicesURLs} from '../../../../_shared/constants';

export const getUsers: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === GET_USERS.START) {
      const { key, ...rest } = action.meta;

      dispatch(
        apiRequest({
          service: Services.auth,
          method: GET,
          url: ServicesURLs.users,
          key: key ?? 'getUsers',
          onError: GET_USERS.ERROR,
          onSuccess: (data: any) => {
            // const storage = new Storage(PROVIDER_KEYS.StealthUser, null, {
            //   set: PROVIDER_KEYS.StealthUser,
            // });
            //
            // storage.set(get(data, '0._id', null));

            dispatch({ type: GET_USERS.SUCCESS, payload: data, key });
          },
          ...rest,
        })
      );
    }
  };
