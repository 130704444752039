import { GET_ANNOUNCEMENT, GET_USER_ANNOUNCEMENTS } from '../../actions';
import { Action } from '../../types';
import { SET_CURRENT_ANNOUNCEMENT } from '../../actions/announcements/setCurrentAnnouncement';

export interface AnnouncementsState {
  currentAnnouncements: Record<string, ClassesNamespace.Announcements>;
  userAnnouncements: Record<string, ClassesNamespace.Announcements[]>;
}

export const AnnouncementsStateDefaultState: AnnouncementsState = {
  currentAnnouncements: {},
  userAnnouncements: {},
};

const announcementReducer = (
  state = AnnouncementsStateDefaultState,
  action: Action
) => {
  const { payload } = action;
  switch (action.type) {
    case SET_CURRENT_ANNOUNCEMENT: {
      return {
        ...state,
        currentAnnouncements: {
          ...state.currentAnnouncements,
          [action.key]: payload,
        },
      };
    }
    case GET_ANNOUNCEMENT.SUCCESS: {
      return Object.assign({}, state, {
        currentAnnouncements: {
          ...state.currentAnnouncements,
          [action.key]: payload,
        },
      });
    }
    case GET_USER_ANNOUNCEMENTS.SUCCESS: {
      return Object.assign({}, state, {
        userAnnouncements: {
          ...state.userAnnouncements,
          [action.key]: payload,
        },
      });
    }
    default:
      return state;
  }
};

export default announcementReducer;
