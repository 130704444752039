import { useEffect } from 'react';
import {
  findUserClasses,
  getLiveClass as _getLiveClass,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionOption, RootState } from '../../redux/types';

export type UseClassMemberProps = {
  key: string;
  options?: ActionOption;
  doFind?: {
    classes?: boolean;
    liveClass?: boolean;
  };
};

export type UseClassMemberType = {
  userClasses: ClassesNamespace.Class[] | undefined;
  getUserClasses: (options?: ActionOption) => void;
  liveClass: ClassesNamespace.LiveClass[] | undefined;
  getLiveClass: (options?: ActionOption) => void;
};

export const useClasses = ({
  key,
  options = {},
  doFind = {
    classes: true,
    liveClass: true,
  },
}: UseClassMemberProps): UseClassMemberType => {
  const dispatch = useDispatch();

  const defaultOptions: ActionOption = {
    key,
    ...options,
  };

  const { userClasses, liveClass } = useSelector(({ classes }: RootState) => ({
    userClasses: classes?.userClasses[key],
    liveClass: classes?.liveClass[key],
  }));

  const getUserClasses = (options?: ActionOption) => {
    dispatch(
      findUserClasses({
        ...defaultOptions,
        ...options,
      })
    );
  };
  const getLiveClass = (options?: ActionOption) => {
    dispatch(
      _getLiveClass({
        ...defaultOptions,
        ...options,
      })
    );
  };

  useEffect(() => {
    if (doFind.classes) {
      getUserClasses();
    }
    if (doFind.liveClass) {
      getLiveClass();
    }
  }, []);

  return {
    userClasses,
    getUserClasses,
    liveClass,
    getLiveClass,
  };
};
