import {createContext, ReactNode, ReactNodeArray, useEffect} from 'react';
import {useAuth, useUIState, useUsers} from '../../hooks';
import {PROVIDER_KEYS} from '../../_shared/constants';
import {getToken} from '../../_shared/utils';
import {RedirectAccount} from '../../components/_shared/RedirectAccount';

export type AuthProviderStateType = {
  auth: AuthNameSpace.User | undefined;
  users?: AuthNameSpace.User[];
  getLoggedIn?: (user: any) => void;
  attributes: {
    readonly: boolean;
  };
};
const initialState: AuthProviderStateType = {
  auth: undefined,
  users: undefined,
  attributes: {
    readonly: false,
  },
};

interface AuthProviderProps {
  children: ReactNode | ReactNodeArray;
}

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const token = getToken();
  const { auth, getLoggedIn } = useAuth({
    doFind: true,
    key: PROVIDER_KEYS.Auth,
  });

  const { users, getUsers } = useUsers({
    key: PROVIDER_KEYS.StealthUser,
  });

  const {
    uiLoaders: {
      [PROVIDER_KEYS.Auth]: loadingUser,
      [PROVIDER_KEYS.StealthUser]: loadingStealthUsers,
    },
  } = useUIState();

  useEffect(() => {
    if (auth?.userType === 'Parent') {
      getUsers({
        params: {
          parents: auth._id,
        },
      });
    }
  }, [auth?._id]);

  const allowedUserTypes = ['Parent', 'Student'];
  if (
    (!token || !auth || !allowedUserTypes.includes(auth?.userType)) &&
    !loadingUser &&
    !loadingStealthUsers
  ) {
    return <RedirectAccount userType={auth?.userType} />;
  }

  const contextValue = {
    attributes: {
      readonly: auth?.userType !== 'Student',
    },
    auth,
    getLoggedIn,
    users,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
