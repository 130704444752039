import { omit } from 'lodash';
import { createSelector } from 'reselect';
import { GradeState } from '../../redux/reducers/grade';

const Grade = (grade: GradeState, key: string) => grade?.grade[key] || [];

export const selectGrade = createSelector(
  Grade,
  (grade: ClassesNamespace.Grade[]) => {
    return grade && omit(grade[0], 'account', 'createdAt', 'student');
  }
);
