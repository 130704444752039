import { ActionOption, RootState } from '../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  findComments,
  postComments,
  updateComments,
  deleteComments,
} from '../../redux/actions';
import { get as LodashGet } from 'lodash';
import { useEffect } from 'react';

export type UseCommentsProps = {
  referenceId?: string | undefined;
  commentType: string;
  key?: string;
  initialOptions?: Record<string, any>;
};

export interface UseCommentsType {
  readonly get: (key: string) => {
    readonly commentsList?: ClassesNamespace.Comments[];
  };
  readonly metadata: (key: string) => {
    pagination: Record<string, any>;
  };

  readonly postComment: (
    referenceId: string,
    commentType: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;

  readonly getComments: (
    referenceId: string,
    commentType: string,
    key: string,
    options?: ActionOption
  ) => void;

  readonly updateComment: (
    commentId: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => void;

  readonly deleteComment: (
    commentId: string,
    key: string,
    options?: ActionOption
  ) => void;
}

export const useComments = (props?: UseCommentsProps): UseCommentsType => {
  const { referenceId, commentType, key, initialOptions } = props ?? {};
  const dispatch = useDispatch();

  const { pagination, comments } = useSelector(
    ({ comments, ui }: RootState) => ({
      comments: comments?.comments,
      pagination: ui?.pagination,
    })
  );

  const get = (key: string) => {
    return {
      commentsList: LodashGet<
        Record<string, ClassesNamespace.Comments[]>,
        string,
        ClassesNamespace.Comments[]
      >(comments, key, []),
    };
  };

  const metadata = (key: string) => ({
    pagination: LodashGet(pagination, key, {}),
  });

  const postComment = (
    referenceId: string,
    commentType: string,
    payload: Record<string, any>,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(
      postComments(referenceId, commentType, payload, { ...options, key })
    );
  };

  const getComments = (
    referenceId: string,
    commentType: string | undefined,
    key: string | undefined,
    options?: ActionOption
  ) => {
    dispatch(findComments(referenceId, commentType, { ...options, key }));
  };

  const updateComment = (
    commentId: string,
    payload: Record<string, any>,
    key: string | undefined,
    options?: ActionOption
  ) => {
    dispatch(updateComments(commentId, payload, { ...options, key }));
  };

  const deleteComment = (
    commentId: string,
    key: string,
    options?: ActionOption
  ) => {
    dispatch(deleteComments(commentId, { ...options, key }));
  };

  useEffect(() => {
    if (referenceId) {
      getComments(
        referenceId,
        commentType,
        key,
        Object.assign({}, initialOptions)
      );
    }
  }, [referenceId]);

  return {
    get,
    metadata,
    postComment,
    getComments,
    updateComment,
    deleteComment,
  };
};
