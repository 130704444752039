import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { DELETE, Services, ServicesURLs } from '../../../../_shared/constants';
import { apiRequest } from '../../../actions/app';
import { DELETE_COMMENTS } from '../../../actions';

export const deleteComments: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === DELETE_COMMENTS.START) {
      const { key, commentId, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.communication,
          method: DELETE,
          url: `${ServicesURLs.comments}/${commentId}`,
          key: key || 'deleteComments',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: DELETE_COMMENTS.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
