import { apiRequest, FIND_ACTIVITIES_BY_PRACTICE } from 'src/redux/actions';
import { Middleware } from 'redux';
import { RootState } from '../../../types';
import { GET, Services, ServicesURLs } from '../../../../_shared/constants';

export const findActivityByPractice: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === FIND_ACTIVITIES_BY_PRACTICE.START) {
      const { key, classId, ...rest } = action.meta;
      dispatch(
        apiRequest({
          service: Services.class,
          method: GET,
          url: `${ServicesURLs.getLoggedInUser}${ServicesURLs.activities}/${classId}`,
          key: key || 'findActivityByPractice',
          ...rest,
          onSuccess: (data: any) => {
            dispatch({
              type: FIND_ACTIVITIES_BY_PRACTICE.SUCCESS,
              payload: data,
              key,
            });
          },
        })
      );
    }
  };
