import {apiRequest, FIND_ACTIVITIES} from '../../../actions';
import {Middleware} from 'redux';
import {RootState} from '../../../types';
import {GET, Services, ServicesURLs} from '../../../../_shared/constants';

export const findActivity: Middleware<unknown, RootState> = ({
  dispatch,
}) => (next) => (action) => {
  next(action);
  const { type } = action;
  if (type === FIND_ACTIVITIES.START) {
    const { key, ...rest } = action.meta;
    dispatch(
      apiRequest({
        service: Services.class,
        method: GET,
        url: ServicesURLs.activities,
        key: key || 'findActivity',
        ...rest,
        onSuccess: (data: any) => {
          dispatch({ type: FIND_ACTIVITIES.SUCCESS, payload: data, key });
        },
      })
    );
  }
};
